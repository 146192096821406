<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <h4 class="ml-1 mb-2">Data Rencana Perawatan</h4>
            <b-col cols="12">
              <label v-if="berkala == false">KM Rencana:</label>
              <p v-if="berkala == false">{{ model.plan_km }}</p>
              <label v-if="berkala == true">Interval:</label>
              <p v-if="berkala == true">{{ model.interval }}</p>
              <label>Deskripsi:</label>
              <p>{{ model.description }}</p>
              <label>Plat Nomor:</label>
              <p>{{ model.nomor }}</p>
              <label v-if="berkala == false">Tanggal Rencana:</label>
              <p v-if="berkala == false">{{ model.plan_date }}</p>
              <!-- <label>Rencana Biaya:</label>
              <p>{{model.plan_cost}}</p>
              <label>Tanggal Realisasi:</label>
              <p>{{model.realisation_date}} </p>
              <label>Biaya Realisasi:</label>
              <p>{{model.realisation_cost}} </p>
              <label>Km Realisasi:</label>
              <p>{{model.realisation_km}} KM</p> -->
              <label v-if="model.author != null">Petugas Pelaporan:</label>
              <p v-if="model.author != null">
                {{ model.author.name }}
              </p>
              <label>Tanggal Masuk:</label>
              <p>{{ tanggal(model.created_at) }}</p>
              <label>Status {{ model.status }}</label>
              <br />
              <span v-if="model.status == 'request'" class="badge rounded-pill bg-info"
                >Permintaan Perawatan</span
              >
              <span v-if="model.status == 'plan'" class="badge rounded-pill bg-info"
                >Permintaan Perawatan Diterima</span
              >
              <span
                v-if="model.status == 'realisation'"
                class="badge rounded-pill bg-info"
                >Perawatan Sedang Diproses</span
              >
              <span v-if="model.status == 'reject'" class="badge rounded-pill bg-danger"
                >Permintaan Perawatan Ditolak</span
              >
              <span v-if="model.status == 'done'" class="badge rounded-pill bg-success"
                >Perawatan Selesai</span
              >
            </b-col>
            <b-col cols="12" class="mt-1">
              <hr />
              <div v-if="loading">
                <b-spinner small />
                Loading...
              </div>
              <div v-else>
                <b-button
                  v-show="model.status != 'request' ? false : true"
                  style="margin-right: 5px"
                  variant="primary"
                  type="button"
                  @click.prevent="editData(model)"
                >
                  Edit
                </b-button>
                <b-button
                  v-show="
                    model.status != 'request' && model.status != 'plan' ? false : true
                  "
                  style="margin-right: 5px"
                  variant="danger"
                  type="button"
                  @click.prevent="confirmDelete(model)"
                >
                  Hapus
                </b-button>
                <b-button
                  v-show="model.status != 'plan' ? false : true"
                  style="margin-right: 5px; margin-top: 5px"
                  variant="success"
                  type="button"
                  @click.prevent="showProses"
                >
                  Proses Rencana Perawatan
                </b-button>
                <b-button
                  v-show="model.status == 'request' ? true : false"
                  style="margin-right: 5px"
                  variant="success"
                  type="button"
                  @click.prevent="showTerima"
                >
                  Setujui
                </b-button>
                <b-button
                  v-show="model.status == 'realisation' ? true : false"
                  style="margin-right: 5px"
                  variant="success"
                  type="button"
                  @click.prevent="showDone"
                >
                  Selesai
                </b-button>
                <b-button
                  v-show="model.status == 'request' ? true : false"
                  style="margin-right: 5px"
                  variant="danger"
                  type="button"
                  @click.prevent="showTolak"
                >
                  Tolak
                </b-button>
                <!-- <b-button
                  style="margin-right: 5px;margin-top: 5px;"
                  variant="info"
                  type="button"
                  @click.prevent="cancel"
                >
                  Kembali
                </b-button> -->
              </div>
            </b-col>
          </b-row>
          <b-modal
            id="modal-center"
            ref="my-modal-setuju"
            :title="title_modal"
            centered
            hide-footer
          >
            <div class="form-group">
              <div class="col-md-12">
                <div class="row mt-1">
                  <div class="col-md-3">
                    <label>Kode Perawatan</label>
                  </div>
                  <div class="col-md-9">
                    <input
                      type="text"
                      class="form-control"
                      :value="formApprove.code"
                      placeholder="Kode Perawatan"
                      required=""
                    />
                  </div>
                </div>

                <div class="row mt-1">
                  <div class="col-md-3">
                    <label>Deksripsi</label>
                  </div>
                  <div class="col-md-9">
                    <input
                      type="text"
                      class="form-control"
                      :value="formApprove.description"
                      placeholder="Deksripsi"
                      required=""
                    />
                  </div>
                </div>

                <div class="row mt-1">
                  <div class="col-md-3">
                    <label>Tanggal Rencana Perawatan</label>
                  </div>
                  <div class="col-md-9">
                    <flat-pickr
                      v-model="formApprove.plan_date"
                      class="form-control"
                      :config="{ dateFormat: 'Y-m-d' }"
                    />
                  </div>
                </div>

                <div class="row mt-1">
                  <div class="col-md-3">
                    <label>Biaya Perencanaan</label>
                  </div>
                  <div class="col-md-9">
                    <input
                      v-model="formApprove.plan_cost"
                      type="number"
                      min="0"
                      class="form-control"
                      placeholder="Biaya Perencanaan"
                      required=""
                    />
                  </div>
                </div>

                <div class="row mt-1">
                  <div class="col-md-3">
                    <label>Biaya Layanan Pemasangan</label>
                  </div>
                  <div class="col-md-9">
                    <input
                      v-model="formApprove.plan_service_cost"
                      type="number"
                      min="0"
                      class="form-control"
                      placeholder="Biaya Layanan Pemasangan"
                      required=""
                    />
                  </div>
                </div>

                <div class="row mt-1">
                  <div class="col-md-3">
                    <label>Perawatan Eksternal</label>
                  </div>
                  <div class="col-md-9">
                    <b-form-checkbox v-model="formApprove.is_external" switch />
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group mt-2 float-right">
              <b-button class variant="primary mr-1" @click="hideModal"> Batal </b-button>
              <b-button type="submit" variant="warning" @click.prevent="submitTerima">
                Terima Rencana Perawatan
              </b-button>
            </div>
          </b-modal>
          <b-modal
            id="modal-center"
            ref="my-modal-tolak"
            :title="title_modal"
            centered
            hide-footer
          >
            <div class="form-group">
              <p>Apa anda ingin menolak Rencana Perawatan ini ?</p>
            </div>

            <div class="form-group mt-2 float-right">
              <b-button class variant="primary mr-1" @click="hideModal"> Batal </b-button>
              <b-button variant="warning" @click.prevent="submitTolak">
                Tolak Rencana Perawatan
              </b-button>
            </div>
          </b-modal>
          <b-modal
            id="modal-center"
            ref="my-modal-proses"
            :title="title_modal"
            centered
            hide-footer
          >
            <div class="form-group">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-3">
                    <label>Nama Perawatan</label>
                  </div>
                  <div class="col-md-9">
                    <input
                      type="text"
                      class="form-control"
                      :value="model.description"
                      placeholder="Nama Perawatan"
                      readonly=""
                    />
                  </div>
                </div>

                <div class="row mt-1">
                  <div class="col-md-3">
                    <label>Deskripsi</label>
                  </div>
                  <div class="col-md-9">
                    <input
                      type="text"
                      class="form-control"
                      :value="model.name"
                      placeholder="Deskripsi"
                      readonly=""
                    />
                  </div>
                </div>

                <div class="row mt-1">
                  <div class="col-md-3">
                    <label>Tanggal Realisasi Perawatan</label>
                  </div>
                  <div class="col-md-9">
                    <flat-pickr
                      v-model="formProcess.realisation_date"
                      class="form-control"
                      :config="{ dateFormat: 'Y-m-d' }"
                    />
                  </div>
                </div>

                <div class="row mt-1">
                  <div class="col-md-3">
                    <label>KM Realisasi</label>
                  </div>
                  <div class="col-md-9">
                    <input
                      v-model="formProcess.realisation_km"
                      type="text"
                      class="form-control"
                      placeholder="KM Realisasi"
                      required=""
                    />
                  </div>
                </div>

                <div class="row mt-1">
                  <div class="col-md-3">
                    <label>Realisasi Biaya</label>
                  </div>
                  <div class="col-md-9">
                    <input
                      v-model="formProcess.realisation_cost"
                      type="number"
                      min="0"
                      class="form-control"
                      placeholder="Realisasi Biaya"
                      required=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group mt-2 float-right">
              <b-button class variant="primary mr-1" @click="hideModal"> Batal </b-button>
              <b-button variant="warning" @click.prevent="submitProses">
                Proses Rencana Perawatan
              </b-button>
            </div>
          </b-modal>
          <b-modal
            id="modal-center"
            ref="my-modal-done"
            :title="title_modal"
            centered
            hide-footer
          >
            <template v-for="(data, index) in dataProses.details">
              <div class="form-group">
                <h5>
                  <u
                    ><b>Detail Kegiatan #{{ index + 1 }}</b></u
                  >
                </h5>

                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-3">
                      <label>Nama Kegiatan</label>
                    </div>
                    <div class="col-md-9">
                      <input
                        type="text"
                        class="form-control"
                        :value="changeActivity(data.activity_type)"
                        placeholder="Nama Kegiatan"
                        readonly=""
                      />
                    </div>
                  </div>

                  <div class="row mt-1">
                    <div class="col-md-3">
                      <label>Nama Perawatan</label>
                    </div>
                    <div class="col-md-9">
                      <input
                        type="text"
                        class="form-control"
                        :value="data.name"
                        placeholder="Nama Perawatan"
                        readonly=""
                      />
                    </div>
                  </div>

                  <div class="row mt-1">
                    <div class="col-md-3">
                      <label>Realisasi Stok</label>
                    </div>
                    <div class="col-md-9">
                      <input
                        v-model="data.realisation_qty"
                        type="number"
                        min="0"
                        class="form-control"
                        placeholder="Realisasi Stok"
                        required=""
                      />
                    </div>
                  </div>

                  <div class="row mt-1">
                    <div class="col-md-3">
                      <label>Realisasi Biaya</label>
                    </div>
                    <div class="col-md-9">
                      <input
                        v-model="data.realisation_cost"
                        type="number"
                        min="0"
                        class="form-control"
                        placeholder="Realisasi Biaya"
                        required=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <div class="form-group mt-2 float-right">
              <b-button class variant="primary mr-1" @click="hideModal"> Batal </b-button>
              <b-button variant="warning" @click.prevent="submitDone"> Selesai </b-button>
            </div>
          </b-modal>
        </b-card>
      </b-col>
      <b-col>
        <b-card>
          <h4 class="mb-2"><feather-icon icon="ListIcon" /> Proses</h4>
          <view-time-line :fields="data_time_line" />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BSpinner,
  BCard,
  BTabs,
  BFormInput,
  BFormCheckbox,
  BTab,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import flatPickr from 'vue-flatpickr-component'
import FormLabel from '@/views/base/form/FormLabel.vue'
import ViewTimeLine from './ViewTimeLine.vue'
import moment from 'moment'

export default {
  components: {
    BFormCheckbox,
    BRow,
    BCol,
    BButton,
    BSpinner,
    BCard,
    BFormInput,
    flatPickr,
    FormLabel,
    ViewTimeLine,
    BTabs,
    BTab,
  },
  data() {
    return {
      loading: false,
      model: {},
      berkala: null,
      baseroute: 'semua-perawatan',
      posturl: '/detail_all_maintenance',
      tombol_hide: true,
      ajukan: '',
      renderComp: true,
      title_modal: null,
      tanggalA: null,
      data_ajukan: [],
      data_item: {},
      data_time_line: [],
      dataProses: {
        details: [],
      },
      formProcess: {
        realisation_date: null,
        realisation_cost: null,
        realisation_km: null,
      },
      formApprove: {
        details: [],
      },
    }
  },
  watch:{
    $route:function(newid){
      if(newid.params.reload){
        this.getData()
      }
    }
  },
  beforeMount() {
    this.$http.get('/staff').then(ref => {
      this.data_ajukan = ref.data.data
    })
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.$http.get(this.posturl + '/' + this.$route.params.id).then(res => {
        this.model = res.data.data
        this.data_time_line = res.data.data.tracks
        this.berkala = res.data.data.berkala
        this.formApprove.details = []
        this.dataProses.details = []
        for (var index in res.data.details) {
          this.dataProses.details.push({
            id: res.data.details[index].id,
            activity_type: res.data.details[index].activity_type,
            name: res.data.details[index].item.name,
            realisation_qty: res.data.details[index].realisation_qty,
            realisation_cost: res.data.details[index].realisation_cost,
          })
          this.formApprove.details.push({
            id: res.data.details[index].id,
            activity_type: res.data.details[index].activity_type,
            cost: res.data.details[index].cost,
            qty: res.data.details[index].qty,
            item_id: res.data.details[index].item_id,
          })
        }
        this.formApprove.code = res.data.code
        this.formApprove.description = res.data.description
        this.formApprove.plan_date = res.data.plan_date
        this.formApprove.plan_cost = res.data.plan_cost
        this.formApprove.plan_service_cost = res.data.plan_service_cost
        this.formApprove.plan_km = res.data.plan_km
        this.formApprove.is_external = res.data.is_external
        this.formApprove.vehicle_id = res.data.vehicle_id
        this.formApprove.workshop_id = res.data.workshop_id
      })
    },
    changeActivity(text) {
      if (text == 'periksa') {
        var value = 'Pemeriksaan'
      } else if (text == 'ganti') {
        var value = 'Penggantian'
      } else {
        var value = 'Penambahan'
      }
      return value
    },
    editData(data) {
      this.$router.push({ name: this.baseroute + '-edit', params: { id: data.id } })
    },
    tanggal(date) {
      return moment(date).local('id').format('DD-MM-YYYY')
    },
    confirmDelete(data) {
      this.$bvModal
        .msgBoxConfirm(
          data.contract_subject != null
            ? 'Anda Ingin Menghapus Data ' +
                data.contract_subject +
                ' ini ? Karena akan berhubungan dengan data lain ?'
            : 'Anda Ingin Menghapus Data ini ? Karena akan berhubungan dengan data lain ?',
          {
            title: 'Please Confirm',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
          }
        )
        .then(confirm => {
          if (confirm) {
            this.$http
              .delete(this.posturl + '/' + data.id)
              .then(() => {
                this.$router.push({ name: this.baseroute })
              })
              .catch(err => {
                this.errorSubmit(err)
              })
          }
        })
    },
    showTerima() {
      this.title_modal = 'Konfirmasi'
      this.$refs['my-modal-setuju'].show()
    },
    showDone() {
      this.title_modal = 'Konfirmasi'
      this.$refs['my-modal-done'].show()
    },
    showTolak() {
      this.title_modal = 'Konfirmasi'
      this.$refs['my-modal-tolak'].show()
    },
    showProses() {
      this.title_modal = 'Konfirmasi'
      this.$refs['my-modal-proses'].show()
    },
    submitTerima() {
      this.$http
        .put(this.posturl + '/' + this.$route.params.id + '/approve', this.formApprove)
        .then(() => {
          this.getData()
          this.$store.dispatch('sendMessage', '1')

          this.$refs['my-modal-setuju'].hide()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Rencana perawatan berhasil diterima',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
    },
    submitTolak() {
      this.$http.put(this.posturl + '/' + this.$route.params.id + '/reject').then(() => {
        this.getData()
          this.$store.dispatch('sendMessage', '1')
        this.$refs['my-modal-tolak'].hide()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Rencana perawatan berhasil ditolak',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      })
    },
    submitProses() {
      this.$http
        .put(this.posturl + '/' + this.$route.params.id + '/process', this.formProcess)
        .then(() => {
          this.getData()
          this.$store.dispatch('sendMessage', '1')
          this.$refs['my-modal-proses'].hide()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Rencana perawatan sedang diproses',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
    },
    submitDone() {
      let formData = {
        details: [],
      }
      for (var index in this.dataProses.details) {
        formData.details.push({
          id: this.dataProses.details[index].id,
          realisation_qty: this.dataProses.details[index].realisation_qty,
          realisation_cost: this.dataProses.details[index].realisation_cost,
        })
      }
      this.$http
        .put(this.posturl + '/' + this.$route.params.id + '/done', formData)
        .then(() => {
          this.getData()
          this.$store.dispatch('sendMessage', '1')
          this.$refs['my-modal-done'].hide()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Rencana perawatan selesai',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
    },
    hideModal() {
      this.$refs['my-modal-proses'].hide()
      this.$refs['my-modal-done'].hide()
      this.$refs['my-modal-setuju'].hide()
      this.$refs['my-modal-tolak'].hide()
    },
    cancel() {
      this.$router.push({ name: this.$route.meta.pageActive })
    },
    errorSubmit(err) {
      const msg = err.response.data.message
      this.$refs['my-modal-proses'].hide()
      this.$refs['my-modal-setuju'].hide()
      this.$refs['my-modal-done'].hide()
      this.$refs['my-modal-tolak'].hide()
      this.$bvToast.toast(msg ? msg : 'Submit error', {
        title: 'Error',
        solid: true,
        variant: 'danger',
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
